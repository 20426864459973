* {
  box-sizing: border-box;
  font-family: 'Lato'
}

::-webkit-scrollbar {
  display: none;
}

::placeholder {
  color: rgba(0,0,0,1);
  opacity: .45
}

.PhoneInputInput {
  background: rgba(0,0,0,0);
  border: none;
  outline: none
}

@media only screen and (max-width: 991px) {
  .fw-mobile {
    font-weight: 600;
    font-size: 18px
  }
  .menu-a {
    color: #ffffff!important
  }
}

button {
  outline: none!important;
  font-family: 'Lato', sans-serif;
}

ion-content {
  /* --offset-bottom: auto!important; */
  /* --overflow: hidden;
  overflow: auto; */
  /* height: 100vh;
  padding-bottom: 200px!important;
  &::-webkit-scrollbar {
    display: none;
  } */
}

ion-action-sheet .action-sheet-button {
  color: #1F2E45!important;
}



input {
  box-sizing: border-box;
}

/* h1 {
  font-size: 24px!important;
  font-family: 'Inter', sans-serif;
  margin-top: 0px!important;
} */

/* h2 {
  font-size: 20px!important;
  font-family: 'Inter', sans-serif
}

h3 {
  color: #6e6e6e;
  font-family: 'Inter', sans-serif
} */

/* ion-card-header.ios {
  display: flex;
  flex-flow: column-reverse;
} */

.shadow {
  box-shadow: 2px 2px 12px rgba(0,0,0,.1);
}

.brelly-input {
  font-weight: 400;
  padding-left: 14px;
  width: 100%;
  height: 44px;
  border: 1px solid rgb(0,0,0);
  background: rgba(255,255,255,0);
  border-radius: 4px;
  font-size: 16px;
  color: rgb(31, 46, 69);
  outline: none;
  font-family: 'Lato'
}

/* @media (prefers-color-scheme: dark) {
  .brelly-input {
    border: 2px solid rgb(255,255,255);
    color: rgb(255,255,255);
  }
} */

.brelly-input:focus {
  border: 1px solid #00ACB0!important;
  box-shadow: 0px 0px 0px 1px #00ACB0;
  /* outline: 1px solid #00ACB0 */
}

.brelly-select {
  color: rgb(31, 46, 69);
  margin-top: 14px;
  width: 100%;
  height: 44px;
  border: 1px solid rgb(0,0,0);
  background: rgba(255,255,255,0);
  border-radius: 4px;
  padding-right: 1px;
  flex-shrink: 0;
  position: relative;
}

.checklist-checkbox {
   width: 24px;
   height: 24px;
   border-radius: 4px;
   border: 1px solid rgb(31, 46, 69);
   transition: all 150ms ease;
   flex-shrink: 0;
   font-size: 28px;
   margin-right: 12px
}

.expense-input {
  width: 100%;
  height: 100%;
  color: #1F2E45;
  border: none;
  outline: none;
  text-align: center;
  font-size: 52px;
  background: rgba(0,0,0,0);
  position: relative;
  z-index: 3
}

.brelly-number-input-value {
  font-size: 16px;
  font-weight: 400;
  color: rgb(31, 46, 69);
  position: absolute;
  left: 0px;
  top: 0px;
  padding-left: 24px;
  z-index: 10;
  color: #1F2E45;
  height: 50px;
  display: flex;
  align-items: center;
}

.hw100 {
  height: 100%;
  width: 100%;
}

.je-ac {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.js-ae {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.jc-ae {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.jb-ae {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.jb-ac {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jb-as {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ja-as {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.ja-ac {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.jc-as {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.jc-ac {
  display: flex;
  align-items: center;
  justify-content: center;
}

.js-ac {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.js-as {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-col {
  flex-direction: column;
}

.padding-md {
  padding-left: 12px;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px
}

.padding-sm {
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px
}

.brelly-input-switch-btn {
  width: 60px;
  height: 54px;
  /* background: #bbbbc9; */
  border-radius: 0px 16px 16px 0px
}

.brelly-input-switch {
  font-weight: 400;
  padding-left: 18px;
  width: calc(100% - 60px);
  height: 54px;
  border-left: 2px solid rgb(31, 46, 69);
  border-top: 2px solid rgb(31, 46, 69);
  border-bottom: 2px solid rgb(31, 46, 69);
  background: rgba(255,255,255,0);
  border-radius: 16px 0px 0px 16px;
  font-size: 16px;
  color: rgb(31, 46, 69);
}

.brelly-input-switch:focus {
  border: 2px solid #00ACB0!important;
  outline: 1px solid #00ACB0
}

.no-text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.account-modal-btn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  border-bottom: 1px solid rgba(0,0,0,.4)
}

.account-modal-btn:last-child {
  border-bottom: none
}

.slide-modal {
  top: 10%!important;
  z-index: 20003!important
}

.drop {
  background: linear-gradient(0deg, rgba(13,52,58,1) 0%, rgba(255,255,255,.5) 100%);
  -webkit-animation: fall .73s linear infinite!important
}

.MuiSlider-thumb {
  background: rgb(0, 172, 176)!important
}

.MuiSlider-rail {
  background: rgba(0, 172, 176, .6)!important
}

.MuiSlider-track {
  background: rgb(0, 172, 176)!important;
  color: rgb(0, 172, 176)!important;
}

.alt-btn {
  margin-top: 12px;
  height: 48px;
  width: 48px;
  background: #d5f5e9;
  color: #32b281;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-family: 'Lato', sans-serif;
  box-shadow: 1px 1px 1px rgba(0, 172, 176, .3);
  cursor: pointer
}

/* fix for canopy connect top padding */
.section {
  padding-top: 20px!important
}
