/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Lato', sans-serif;
  /** primary **/
  --ion-color-primary: #00ACB0;
  --ion-color-primary-rgb: 0, 172, 176;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2e8f78;
  --ion-color-primary-tint: #88ebca;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #1F2E45;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #00ACB0;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}



body.dark {
  --ion-color-primary: #00ACB0;
  --ion-color-primary-rgb: 0, 172, 176;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2e8f78;
  --ion-color-primary-tint: #88ebca;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #1F2E45;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #1F2E45;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #1F2E45;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #1F2E45;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #1F2E45;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #1F2E45;

  --ion-card-background: #1c1c1d;
}

.brelly-modal-footer {
  width: 100%;
  left: 0px;
  bottom: 0px;
  padding-bottom: 120px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
}

.brelly-modal-header {
  width: 100%;
  left: 0px;
  top: 0px;
  background: white;
  padding: 16px;
  position: relative;
}

.breally-modal-header-close {
  transform: rotate(45deg);
  color: rgb(196,196,196);
  font-size: 36px;
  position: absolute;
  top: -4px;
  right: 4px
}

.brelly-tutorial-header {
  color: rgb(45, 60, 87);
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}

.brelly-tutorial-content {
  color: rgb(45, 60, 87);
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

.caps {
  text-transform: uppercase;
}

.brelly-link {
  color: rgb(0, 172, 176);
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  cursor: pointer
}

.brelly-h1 {
  color: rgb(45, 60, 87);
  font-size: 18px;
  font-family: 'Lato', sans-serif;
}

.brelly-h1-5 {
  color: rgb(45, 60, 87);
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

.brelly-h2 {
  color: rgb(45, 60, 87);
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}

.brelly-h3 {
  color: rgb(45, 60, 87);
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}

.brelly-h5 {
  color: rgb(45, 60, 87);
  font-size: 13px;
  font-family: 'Lato', sans-serif;
}

.brelly-h6 {
  color: rgb(45, 60, 87);
  font-size: 12px;
  font-family: 'Lato', sans-serif;
}

.brelly-card {
  border-radius: 9px;
  box-shadow: 0px 0px 10px rgba(0,0,0,.05);
  background: white;
  --background: white;
}

.brelly-card2 {
  background: #eeeeee;
  --background: #eeeeee;
}

.brelly-toolbar {
  /* background: var(--ion-toolbar-background, var(--ion-color-step-50, #f2f2f2));
  --background: var(--ion-toolbar-background, var(--ion-color-step-50, #f2f2f2)); */

  background: white;
  --background: white;
}

.brelly-content {
  background: #F5F5F5;
  --background: #F5F5F5;
}

.brelly-light-text {
  color: rgb(144,144,144)
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  --ion-item-background: var(--ion-color-step-150);
}

body.dark .brelly-light-text {
  color: rgb(144,144,144)
}

body.dark .brelly-input {
  border: 2px solid rgb(255,255,255);
  color: rgb(255,255,255);
}

body.dark .brelly-select {
  border: 2px solid rgb(255,255,255);
  color: rgb(255,255,255);
}

body.dark .checklist-checkbox {
   border: 1px solid rgb(255, 255, 255);
}

body.dark .brelly-content {
  background: var(--ion-card-background, var(--ion-item-background, var(--ion-background-color, #fff)));
}

body.dark .brelly-toolbar {
  background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
  --background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
}

body.dark .brelly-card {
  background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
  --background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
}

body.dark .brelly-card2 {
  background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
  --background: var(--ion-toolbar-background, var(--ion-color-step-50, #f7f7f7));
}

body.dark .brelly-modal-footer {
  background: #1a1a1a;
}

body.dark .brelly-modal-header {
  background: #1a1a1a;
}

body.dark .brelly-tutorial-header {
  color: white;
}

body.dark .brelly-tutorial-content {
  color: white;
}

body.dark .brelly-h1 {
  color: white;
}

body.dark .brelly-h1-5 {
  color: white;
}

body.dark .brelly-h2 {
  color: white;
}

body.dark .brelly-h3 {
  color: white;
}

body.dark .brelly-h5 {
  color: white;
}

body.dark .brelly-h6 {
  color: white;
}

body.dark .brelly-content {
  background: #1a1a1a;
  --background: #1a1a1a;
}

.brelly-item-hover:hover {
  background: #E9F5FF
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #FAFAFA;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #FAFAFA;
}

.ion-padding {
  /* --padding-start: 20px!important;
  --padding-end: 20px!important;
  --padding-top: 20px!important;
  --padding-bottom: 20px!important; */
}

h1 {
 color: black;
 font-size: 22px;
 font-family: Lato;
 font-weight: 700;
}

h2 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 135.5%;
}

h3 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.dash-table-header {
  color: #64666B;
  font-size: 13px;
  font-family: Lato;
  font-weight: 500;
  line-height: 25.5px;
}

.dash-table-btn {
  color: var(--primary-text, #2D3C57);
  font-size: 13px;
  font-family: Lato;
  font-weight: 600;
  line-height: 25.5px;
  cursor: pointer;
}

.brelly-text-btn {
  color: var(--secondary, #00ACB0);
  font-size: 16px;
  font-family: Lato;
  font-weight: 600;
  line-height: 22.857px;
  cursor: pointer
}

.dash-bullet {
  background: var(--primary-text, #00ACB0);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px
}

.dash-task-item {
  color: var(--primary-text, #2D3C57);
  font-size: 15px;
  font-family: Lato;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dash-highlight {
  color: var(--secondary, #2D3C57);
  font-size: 46px;
  font-family: Lato;
  font-weight: 700;
  line-height: 110%;
  line-height: 44px
}

.dash-highlight-label {
  color: #64666B;
  font-size: 15px;
  font-family: Lato;
  line-height: 28px;
  font-weight: 500
}

.dash-box {
  width: 100%;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.02);
  padding: 10px 24px;
  font-family: Lato;
}

.dash-box-padded {
  padding: 18px 24px;
}

.dash-box-title {
  color: #64666B;
  font-size: 19px;
  font-family: Lato;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.chat-ribbon-btn {
  color: var(--secondary-text, #64666B);
  font-size: 13px;
  font-family: Lato;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.menu-link {
  font-family: 'Lato';
  font-style: normal;
  font-size: 19px;
  line-height: 28px;
  color: #64666B;
}

.bold {
  font-family: 'Lato';
}

.green {
  color: #00ACB0
}

.green-bg {
  background: #00ACB0
}

.grey {
  color: #D8D8D8
}

.brelly-web-h1 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 110%;
  color: #2D3C57;
}

.brelly-a {
  text-decoration: none;
  color: #00ACB0;
}

.brelly-a:hover {
  text-decoration: underline;
  color: #00ACB0;
}

.dash-menu-btn:hover {
  background: rgba(206, 242, 243, 0.35)
}

.dash-account-input {
  width: calc(50% - 12px);
  height: 50px;
  background: #F5F5F5;
  border-radius: 12px;
  border: none;
  padding-left: 18px;
  font-family: 'Lato';
}

.dash-account-input:focus {
  outline: 2px solid #00ACB0
}

.dash-clear-btn {
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  border-radius: 10px
}

.dash-clear-btn:hover {
  background: #F5F5F5;
  box-shadow: 1px 1px 1px rgba(0,0,0,.1)
}

.brelly-h4 {
  color: var(--secondary-text, #9398A0);
  font-size: 15px;
  font-family: Lato;
  font-weight: 500
}

.brelly-h5 {
  color: var(--secondary-text, #9398A0);
  font-size: 13px;
  font-family: Lato;
  font-weight: 600;
  line-height: 28px;
}

.home-02-test {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 18H14M16.1804 22H7.81965C5.5109 22 3.6393 20.214 3.6393 18.0108V13.133C3.6393 12.4248 3.34447 11.7456 2.81969 11.2448C1.60381 10.0845 1.76187 8.16205 3.15251 7.19692L9.54124 2.763C11.0071 1.74567 12.9929 1.74567 14.4588 2.763L20.8475 7.19691C22.2381 8.16205 22.3962 10.0845 21.1803 11.2448C20.6555 11.7456 20.3607 12.4248 20.3607 13.133V18.0108C20.3607 20.214 18.4891 22 16.1804 22Z' stroke='%239398A0' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
}
